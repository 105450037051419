.register .gradient-custom-3 {
  /* fallback for old browsers */
  background: lawngreen;
  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(
    to right,
    rgb(124, 252, 0, 0.7),
    rgb(255, 255, 0, 0.7)
  );
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(
    to right,
    rgb(124, 252, 0, 0.7),
    rgb(255, 255, 0, 0.7)
  );
}

.register .gradient-custom-4 {
  /* fallback for old browsers */
  background: lawngreen;
  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(
    to right,
    rgb(124, 252, 0, 1),
    rgb(255, 255, 0, 0.5)
  );
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(
    to right,
    rgb(124, 252, 0, 1),
    rgb(255, 255, 0, 0.5)
  );
}

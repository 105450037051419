/* body{margin-top:20px;
background:#eee;
}

.btn {
    margin-bottom: 5px;
}

.grid {
    position: relative;
    width: 100%;
    background: #fff;
    color: #666666;
    border-radius: 2px;
    margin-bottom: 25px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

.grid .grid-body {
    padding: 15px 20px 15px 20px;
    font-size: 0.9em;
    line-height: 1.9em;
} */

.ride-detail table tr td {
  background-color: #e6e6ed !important;
}

.ride-detail table tr td.rate {
  color: #f39c12;
  line-height: 50px;
}

.ride-detail table tr:hover {
  cursor: pointer;
}

.ride-detail table tr td.image {
  width: 50px;
}

.ride-detail table tr td img {
  width: 50px;
  height: 50px;
}

.ride-detail table tr td.rate {
  color: #f39c12;
  line-height: 50px;
}

.ride-detail table tr td.price {
  font-size: 1.5em;
  line-height: 50px;
}

.ride-detail #price1,
.ride-detail #price2 {
  display: inline;
  font-weight: 600;
}

/*search box css start here*/

.search-sec {
  padding: 2rem;
}

.search-slt {
  display: block;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  height: calc(2.29rem + 2px) !important;
  border-radius: 0;
}

.wrn-btn {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  /* height: calc(3rem + 2px) !important; */
  height: calc(2.29rem + 2px) !important;
  border-radius: 0;
}

@media (min-width: 392px) {
  .search-sec {
    /* position: relative; */
    bottom: 250px;
    background: rgba(26, 70, 104, 0.51);
  }
}

@media (max-width: 992px) {
  .search-sec {
    background: #1a4668;
  }
}

.page-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.page-404 .title {
  font-size: 36px;
  margin-bottom: 20px;
}

.not-found-message {
  font-size: 18px;
  color: #888;
}

.back-to-home {
  margin-top: 20px;
  font-size: 18px;
  text-decoration: none;
  color: black;
}

.back-to-home:hover {
  text-decoration: underline;
}

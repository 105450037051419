.jumbotron {
    /* margin: 15px 30px 0px 30px; */
    /* border-radius: 10px; */
    /* background: linear-gradient(rgba(0, 0, 250, 0.25), rgba(125, 250, 250, 0.45)), */
    /* url(https://source.unsplash.com/1600x1050/?nature); */
    /* url(/images/car_driver.jpeg); */
    /* url(../../public/avatar.jpg); */
    /* background-repeat: no-repeat; */
    /* background-attachment: fixed; */
    /* -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain; */
    /* background-size: contain; */
    /* background-size: 100% 100%; */
    /* color: white !important; */
    background-size: 100% 100% !important;
    height: 340px;
    /* width: 600px; */
}

@media (min-width: 600px) and (max-width: 900px) {
    .jumbotron {
        height: 500px;
    }
}

@media (min-width: 900px) {
    .jumbotron {
        height: 500px;
        /* width: 80%; */
    }
}
@media (min-width: 768px) {
  .booking-detail .booking-pickup-drop {
    border-right: 1px solid #dee2e6 !important;
  }
  .track-line {
    height: 2px !important;
    background-color: #488978;
    opacity: 1;
  }
  .track-arrow {
    height: 50px !important;
    color: #2df362;
    opacity: 1;
  }
  .booking-detail .dot {
    height: 10px;
    width: 10px;
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 0px;
    background-color: #488978;
    border-radius: 50%;
    display: inline-block;
  }
  .booking-detail .big-dot {
    height: 25px;
    width: 25px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    background-color: #488978;
    border-radius: 50%;
    display: inline-block;
  }
  .booking-detail .big-dot i {
    font-size: 12px;
  }
  .booking-detail .card-stepper {
    z-index: 0;
  }
}

@media (max-width: 768px) {
  .booking-detail .booking-pickup-drop {
    border: none !important;
  }
}

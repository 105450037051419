header {
  background-color: #222;
  /* height: 100px; */
}

.header-div {
  padding: 20px;
  color: #fff;
}

header .title {
  font-size: 24px;
  margin: 0;
}

header .title a {
  text-decoration: none;
  color: #fff;
}

.navbar {
  display: flex;
  justify-content: flex-end;
}

.nav-list {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.nav-item {
  margin-left: 25px;
}

.nav-item a {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 768px) {
  .navbar {
    justify-content: center;
    /* margin-top: 10px; */
  }
  .nav-item {
    margin: 0 10px;
  }
}

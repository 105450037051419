/*search box css start here*/

.search-sec {
  padding: 2rem;
}

.search-slt {
  display: block;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  height: calc(2.29rem + 2px) !important;
  border-radius: 0;
}

.wrn-btn {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  /* height: calc(3rem + 2px) !important; */
  height: calc(2.29rem + 2px) !important;
  border-radius: 0;
}

@media (min-width: 392px) {
  .search-sec {
    /* position: relative; */
    bottom: 250px;
    background: black;
  }
}

@media (max-width: 992px) {
  .search-sec {
    background: black;
  }
}

@media (max-width: 767.98px) {
  .border-sm-start-none {
    border-left: none !important;
  }
}
